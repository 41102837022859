import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENUM_EN from "./assets/i18n/enum/en.json";
import ENUM_LT from "./assets/i18n/enum/lt.json";
import ENUM_RU from "./assets/i18n/enum/ru.json";

export const t = (key, config) => {
  return i18n.t(key, config);
};

i18n.use(initReactI18next).init({
  fallbackLng: "lt",
  saveMissing: true,
  debug: process.env.NODE_ENV !== "production",
  resources: {
    en: {
      translation: ENUM_EN,
    },
    lt: {
      translation: ENUM_LT,
    },
    ru: {
      translation: ENUM_RU,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

i18n.addResourceBundle("en", "enum", ENUM_EN);
i18n.addResourceBundle("lt", "enum", ENUM_LT);
i18n.addResourceBundle("ru", "enum", ENUM_RU);

export default i18n;
